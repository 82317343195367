/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "„Nicht der Kritiker zählt, nicht der Mann, der darauf hinweist, wie der Starke strauchelt oder wo der Handelnde es besser hätte machen können. Das Verdienst gehört dem, der tatsächlich in der Arena steht, dessen Gesicht von Staub und Schweiß und Blut gezeichnet ist; der sich tapfer bemüht; der sich irrt, der immer wieder scheitert, weil es keine Anstrengung ohne Fehler und Unzulänglichkeiten gibt; der sich aber tatsächlich bemüht, die Taten zu vollbringen; der die große Begeisterung, die große Hingabe kennt; der sich für eine würdige Sache verausgabt; der im besten Fall am Ende den Triumph der hohen Leistung kennt, und der im schlimmsten Fall, wenn er scheitert, wenigstens scheitert, indem er Großes wagt, so dass sein Platz niemals bei jenen kalten und furchtsamen Seelen sein wird, die weder Sieg noch Niederlage kennen.“");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
